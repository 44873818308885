const ru = {
    "App.caption": "Cannawayz",

    "App.header.ads.caption": "Реклама",
    "App.header.users.caption": "Пользователи",
    "App.header.brands.caption": "Бренды",
    "App.header.shops.caption": "Магазины",
    "App.header.products.caption": "Продукты",

    "AvatarImage.upload.title": "Загрузить новое изображение",
    "AvatarImage.delete.title": "Удалить это изображение",
    "AvatarImage.deleteConfirm.title": "Удалить это изображение?",
    "AvatarImage.deleteConfirm.confirmButton.caption": "Удалить",
    "AvatarImage.deleteConfirm.cancelButton.caption": "Отмена",
    "AvatarImage.deleteConfirm.message": "Вы действительно хотите удалить это изображение?",

    "ProfileMenu.logout.caption": "Выйти",
    "ProfileMenu.logout.title": "Завершить сеанс и выйти",
    "ProfileMenu.logoutAll.caption": "Выйти везде",
    "ProfileMenu.logoutAll.title": "Завершить сеанс на всех устройствах",

    "ColorModeSwitcher.light.title": "Переключиться в светлый режим",
    "ColorModeSwitcher.dark.title": "Переключиться в темный режим",

    "AdminFilterButton.caption": "Админы",
    "AdminFilterButton.title": "Переключить поиск администраторов",

    "DebouncedSearchField.placeholder": "Поиск",

    "FuzzyFilterButton.caption": "Нечеткий поиск",
    "FuzzyFilterButton.title": "Включить/выключить нечеткий поиск",

    "PublishedFilterButton.published": "Опубликован",
    "PublishedFilterButton.unpublished": "Не опубликован",
    "PublishedFilterButton.yes": "Да",
    "PublishedFilterButton.no": "Нет",
    "PublishedFilterButton.all": "Все",

    "WithAdminsFilterButton.withAdmins": "Есть админы",
    "WithAdminsFilterButton.withNoAdmins": "Без админов",
    "WithAdminsFilterButton.yes": "Да",
    "WithAdminsFilterButton.no": "Нет",
    "WithAdminsFilterButton.all": "Все",

    "LocationsFilterButton.clear.caption": "Очистить",
    "LocationsFilterButton.countries.loadingMessage": "Загрузка стран",
    "LocationsFilterButton.countries.noMatchesMessage": "Нет совпадений",
    "LocationsFilterButton.countries.emptyMessage": "Нет стран",
    "LocationsFilterButton.countries.placeholder": "Поиск стран",

    "LocationsFilterButton.regions.loadingMessage": "Загрузка регионов",
    "LocationsFilterButton.regions.noMatchesMessage": "Нет совпадений",
    "LocationsFilterButton.regions.emptyMessage": "Нет регионов",
    "LocationsFilterButton.regions.placeholder": "Поиск регионов",

    "LocationsFilterButton.cities.loadingMessage": "Загрузка городов",
    "LocationsFilterButton.cities.noMatchesMessage": "Нет совпадений",
    "LocationsFilterButton.cities.emptyMessage": "Нет городов",
    "LocationsFilterButton.cities.placeholder": "Поиск городов",

    "LocationsFilterButton.locations": "Города и страны",
    "LocationsFilterButton.all": "Все",

    "RetailerServicesFilterButton.caption": "Сервисы",
    "RetailerServicesFilterButton.all": "Все",
    "RetailerServicesFilterButton.storefront": "Storefront",
    "RetailerServicesFilterButton.doctor": "Doctor",
    "RetailerServicesFilterButton.delivery": "Delivery",
    "RetailerServicesFilterButton.smoke": "Smoke shop",
    "RetailerServicesFilterButton.cbd": "CBD shop",

    "toasts.info.title": "Информация",
    "toasts.success.title": "Успешно",
    "toasts.warning.title": "Внимание",
    "toasts.error.title": "Ошибка",

    "LoginPage.caption": "Авторизация",
    "LoginPage.email.caption": "E-mail",
    "LoginPage.email.required.message": "Необходимо ввести e-mail",
    "LoginPage.password.caption": "Пароль",
    "LoginPage.password.required.message": "Необходимо ввести пароль",
    "LoginPage.button.caption": "Войти",

    "ErrorPage.code": "Упс",
    "ErrorPage.title": "Что-то пошло не так",
    "ErrorPage.description": "Случилась ошибочная ошибка и требуется перезагрузить страницу.",
    "ErrorPage.backButton.caption": "Назад",

    "WelcomePage.services.title": "Службы",
    "WelcomePage.services.description": "Состояние служб сервера",
    "WelcomePage.services.id": "Идентификатор экземпляра",
    "WelcomePage.services.status": "Состояние",
    "WelcomePage.services.node": "Хост",
    "WelcomePage.services.address": "Адрес",
    "WelcomePage.services.port": "Порт",
    "WelcomePage.services.started": "Запущен",
    "WelcomePage.services.registered": "Зарегистрирован",
    "WelcomePage.services.lastRenewal": "Последнее обновление",
    "WelcomePage.instances.expand": "Развернуть",
    "WelcomePage.instances.hide": "Скрыть",
    "WelcomePage.service.AUTH-SERVICE.description": "Сервис авторизации",
    "WelcomePage.service.DISCOVERY-SERVICE.description": "Сервис обнаружения",
    "WelcomePage.service.GATEWAY-SERVICE.description": "Диспетчер внешних запросов",
    "WelcomePage.service.GRAPHQL-SERVICE.description": "Диспетчер запросов GraphQL",
    "WelcomePage.service.LOCATION-SERVICE.description": "Сервис геолокации",
    "WelcomePage.service.USER-SERVICE.description": "Сервис пользователей",
    "WelcomePage.service.VENDOR-SERVICE.description": "Сервис магазинов и брендов",

    "NotFoundErrorPage.title": "Страница не найдена",
    "NotFoundErrorPage.description": "Запрашиваемая страница не найдена, удалена или временно недоступна.",

    "PreventNavigationDialog.title": "Вы действительно хотите покинуть страницу?",
    "PreventNavigationDialog.message": "Изменения не были сохранены и будут потеряны при переходе на другой адрес.",
    "PreventNavigationDialog.keep.caption": "Остаться",
    "PreventNavigationDialog.discard.caption": "Отменить изменения",

    "BrandListPage.search.placeholder": "Введите текст для поиска",
    "BrandListPage.addButton.caption": "Добавить",
    "BrandListPage.addButton.title": "Добавить новый бренд",

    "BrandPage.deleteButton.caption": "Удалить",
    "BrandPage.deleteButton.title": "Удалить бренд",
    "BrandPage.resetButton.caption": "Сбросить",
    "BrandPage.resetButton.title": "Сбросить изменения",
    "BrandPage.saveButton.caption": "Сохранить",
    "BrandPage.saveButton.title": "Сохранить изменения",
    "BrandPage.backButton.caption": "Назад",
    "BrandPage.backButton.title": "Назад к брендам",

    "ShopPage.deleteButton.caption": "Удалить",
    "ShopPage.deleteButton.title": "Удалить магазин",
    "ShopPage.resetButton.caption": "Сбросить",
    "ShopPage.resetButton.title": "Сбросить изменения",
    "ShopPage.saveButton.caption": "Сохранить",
    "ShopPage.saveButton.title": "Сохранить изменения",
    "ShopPage.backButton.caption": "Назад",
    "ShopPage.backButton.title": "Назад к магазинам",
    "ShopPage.shopGroup.title": "Магазин",
    "ShopPage.shopGroup.description": "Информация о магазине",
    "ShopPage.form.type.caption": "Тип магазина",
    "ShopPage.form.licenseType.caption": "Тип лицензии",
    "ShopPage.form.retailerServices.caption": "Ритейл сервисы",
    "ShopPage.form.minAge.caption": "Мин. возраст",
    "ShopPage.workHoursTab.title": "Часы работы",
    "ShopPage.workHoursGroup.title": "Часы работы",
    "ShopPage.workHoursGroup.description": "Часы работы магазина",

    "ShopWorkHours.1.caption": "Понедельник",
    "ShopWorkHours.2.caption": "Вторник",
    "ShopWorkHours.3.caption": "Среда",
    "ShopWorkHours.4.caption": "Четверг",
    "ShopWorkHours.5.caption": "Пятница",
    "ShopWorkHours.6.caption": "Суббота",
    "ShopWorkHours.7.caption": "Воскресенье",
    "ShopWorkHours.allDay.caption": "Весь день",
    "ShopWorkHours.startTime.caption": "Открытие",
    "ShopWorkHours.endTime.caption": "Закрытие",

    "VendorForm.commonGroup.title": "Основное",
    "VendorForm.commonGroup.description": "Основная информация",
    "VendorForm.form.name.caption": "Наименование",
    "VendorForm.form.name.required": "Необходимо заполнить наименование",
    "VendorForm.form.slug.caption": "Ссылка",
    "VendorForm.form.published.caption": "Опубликован",
    "VendorForm.form.verified.caption": "Проверен",
    "VendorForm.form.merchant.caption": "Статус продавца",
    "VendorForm.form.bestChoice.caption": "Лучший выбор",
    "VendorForm.form.onlineOrderingGroup.enabledForPickup": "",
    "VendorForm.form.onlineOrderingGroup.enabledForDelivery": "",
    "VendorForm.form.email.caption": "E-mail",
    "VendorForm.form.email.validate": "Неверный e-mail адрес",
    "VendorForm.form.phone.caption": "Телефон",
    "VendorForm.form.regPhone.caption": "Телефон регистрации",
    "VendorForm.addressTab.title": "Адреса",
    "VendorForm.addressGroup.title": "Адрес",
    "VendorForm.addressGroup.description": "Страна, штат, город, адрес и другая адресная информация",
    "VendorForm.form.countryCode.caption": "Страна",
    "VendorForm.form.regionCode.caption": "Штат/регион",
    "VendorForm.form.city.caption": "Город",
    "VendorForm.form.zipCode.caption": "Почтовый индекс",
    "VendorForm.form.address.caption": "Адрес",
    "VendorForm.form.address2.caption": "Дополнительный адрес",
    "VendorForm.form.timezone.caption": "Временная зона",
    "VendorForm.mediaTab.title": "Медиа",
    "VendorForm.avatarGroup.title": "Медиа",
    "VendorForm.avatarGroup.description": "Логотипы, изображения и медиа",
    "VendorForm.form.avatarProfile.caption": "Изображение профиля",
    "VendorForm.form.avatarImage.largeUrl.caption": "Большое изображение",
    "VendorForm.form.avatarImage.mediumUrl.caption": "Среднее изображение",
    "VendorForm.form.avatarImage.smallUrl.caption": "Малое изображение",
    "VendorForm.mediaTable.url": "Медиа-ссылка",
    "VendorForm.mediaTable.addButton.caption": "Добавить",
    "VendorForm.mediaTable.addButton.description": "Добавить новую медиа-ссылку",
    "VendorForm.mediaTable.deleteConfirm.title": "Удалить медиа-ссылку?",
    "VendorForm.mediaTable.deleteConfirm.confirmButton.caption": "Удалить",
    "VendorForm.mediaTable.deleteConfirm.cancelButton.caption": "Отмена",
    "VendorForm.mediaTable.deleteConfirm.message": "Вы действительно хотите удалить эту медиа-ссылку?",
    "VendorForm.mediaTable.MediaEditDialog.title": "Редактирование медиа-ссылки",
    "VendorForm.mediaTable.MediaEditDialog.upload.title": "Загрузить файл на сервер",
    "VendorForm.mediaTable.MediaEditDialog.cancelButton.caption": "Отмена",
    "VendorForm.mediaTable.MediaEditDialog.okButton.caption": "OK",
    "VendorForm.seoTab.title": "СЕО",
    "VendorForm.seoGroup.title": "СЕО",
    "VendorForm.seoGroup.description": "Дополнительная информация для поисковых сайтов",
    "VendorForm.form.pageTitle.caption": "Заголовок страницы (page title)",
    "VendorForm.form.pageKeywords.caption": "Ключевые слова (page keywords)",
    "VendorForm.form.pageDescription.caption": "Описание (page description)",
    "VendorForm.socialTab.title": "Соцсети",
    "VendorForm.socialGroup.title": "Соцсети",
    "VendorForm.socialGroup.description": "Ссылки на интернет-ресурсы и страницы в соцсетях",
    "VendorForm.socialGroup.WWW.caption": "Web сайт",
    "VendorForm.socialGroup.YOUTUBE.caption": "YouTube",
    "VendorForm.socialGroup.FACEBOOK.caption": "Facebook",
    "VendorForm.socialGroup.INSTAGRAM.caption": "Instagram",
    "VendorForm.socialGroup.TWITTER.caption": "X (ранее Twitter)",
    "VendorForm.licenseGroup.title": "Лицензии",
    "VendorForm.licenseGroup.description": "Лицензии",
    "VendorForm.socialGroup.LICENSE_1.caption": "Лицензия (1)",
    "VendorForm.socialGroup.LICENSE_2.caption": "Лицензия (2)",
    "VendorForm.socialGroup.LICENSE_3.caption": "Лицензия (3)",
    "VendorForm.socialGroup.LICENSE_4.caption": "Лицензия (4)",
    "VendorForm.socialGroup.LICENSE_5.caption": "Лицензия (5)",
    "VendorForm.amenitiesGroup.title": "Услуги",
    "VendorForm.amenitiesGroup.description": "Услуги",
    "VendorForm.amenitiesGroup.AMENITIES_AGE_18.caption": "Age 18+",
    "VendorForm.amenitiesGroup.AMENITIES_AGE_19.caption": "Age 19+",
    "VendorForm.amenitiesGroup.AMENITIES_AGE_21.caption": "Age 21+",
    "VendorForm.amenitiesGroup.AMENITIES_ACCESSIBLE.caption": "Accessible",
    "VendorForm.amenitiesGroup.AMENITIES_ATM.caption": "ATM",
    "VendorForm.amenitiesGroup.AMENITIES_BRAND_VERIFIED.caption": "Brand verified",
    "VendorForm.amenitiesGroup.AMENITIES_CURBSIDE_PICKUP.caption": "Curbside pickup",
    "VendorForm.amenitiesGroup.AMENITIES_PHOTOS.caption": "Photos",
    "VendorForm.amenitiesGroup.AMENITIES_SECURITY.caption": "Security",
    "VendorForm.adminsTab.title": "Администраторы",
    "VendorForm.adminsGroup.title": "Администраторы",
    "VendorForm.adminsGroup.description": "Администраторы",
    "VendorForm.adminsTable.deleteConfirm.title": "Удалить администратора?",
    "VendorForm.adminsTable.deleteConfirm.confirmButton.caption": "Удалить",
    "VendorForm.adminsTable.deleteConfirm.cancelButton.caption": "Отмена",
    "VendorForm.adminsTable.deleteConfirm.message":
        "Вы действительно хотите удалить выбранного пользователя из администраторов?",
    "VendorForm.adminsTable.addButton.caption": "Добавить",
    "VendorForm.adminsTable.addButton.description": "Добавить нового администратора",
    "VendorForm.productsTab.title": "Продукты",

    "ShopListPage.search.placeholder": "Введите текст для поиска",

    "UserListPage.search.placeholder": "Введите текст для поиска",
    "UserListPage.addButton.caption": "Добавить",
    "UserListPage.addButton.title": "Добавить нового пользователя",

    "UserRoleEditor.cannawayz:accidents": "Инциденты",
    "UserRoleEditor.cannawayz:accidents:view": "cw:acc:view",
    "UserRoleEditor.cannawayz:accidents:admin": "cw:acc:admin",
    "UserRoleEditor.cannawayz:ads": "Реклама",
    "UserRoleEditor.cannawayz:ads:view": "cw:ads:view",
    "UserRoleEditor.cannawayz:ads:admin": "cw:ads:admin",
    "UserRoleEditor.cannawayz:brands": "Бренды",
    "UserRoleEditor.cannawayz:brands:view": "cw:brands:view",
    "UserRoleEditor.cannawayz:brands:admin": "cw:brands:admin",
    "UserRoleEditor.cannawayz:shops": "Магазины",
    "UserRoleEditor.cannawayz:shops:view": "cw:shops:view",
    "UserRoleEditor.cannawayz:shops:admin": "cw:shops:admin",
    "UserRoleEditor.cannawayz:users": "Пользователи",
    "UserRoleEditor.cannawayz:users:view": "cw:users:view",
    "UserRoleEditor.cannawayz:users:admin": "cw:users:admin",
    "UserRoleEditor.cannawayz:super:admin": "CW:Админ",
    "UserRoleEditor.cannaexchange:super:admin": "CE:Админ",
    "UserRoleEditor.none.caption": "Нет доступа",
    "UserRoleEditor.view.caption": "Только просмотр",
    "UserRoleEditor.admin.caption": "Полный доступ",
    "UserRoleEditor.fullAccess.caption": "Полный доступ ко всем разделам сайта",

    "UserPage.commonGroup.title": "Основное",
    "UserPage.commonGroup.description": "Основная информация о пользователе",

    "UserPage.addressGroup.title": "Адресная информация",
    "UserPage.addressGroup.description":
        "Страна, штат, город, адрес проживания и другая адресная информация о пользователе",

    "UserPage.additionalGroup.title": "Дополнительно",
    "UserPage.additionalGroup.description": "Дополнительная информация",

    "UserPage.adminGroup.title": "Администратор",
    "UserPage.adminGroup.description": "Роли администратора",

    "UserPage.form.username.caption": "Имя пользователя",
    "UserPage.form.username.required": "Необходимо заполнить имя пользователя",
    "UserPage.form.email.caption": "E-mail",
    "UserPage.form.email.required": "Необходимо заполнить e-mail",
    "UserPage.form.email.validate": "Неверный e-mail адрес",
    "UserPage.form.firstName.caption": "Имя",
    "UserPage.form.lastName.caption": "Фамилия",
    "UserPage.form.birthDate.caption": "Дата рождения",
    "UserPage.form.gender.caption": "Пол",
    "UserPage.form.countryCode.caption": "Страна",
    "UserPage.form.regionCode.caption": "Штат/Регион",
    "UserPage.form.city.caption": "Город",
    "UserPage.form.address.caption": "Адрес",
    "UserPage.form.phone.caption": "Телефон",
    "UserPage.form.notificationPhone.caption": "Телефон для оповещений",
    "UserPage.form.timezone.caption": "Временная зона",
    "UserPage.form.locale.caption": "Локаль",
    "UserPage.form.balance.caption": "Баланс",
    "UserPage.form.balance.min": "Баланс не может быть отрицательным",
    "UserPage.form.merchant.caption": "Статус продавца",
    "UserPage.form.blockedTo.caption": "Заблокирован до",
    "UserPage.form.lastLoggedIn.caption": "Последний вход",
    "UserPage.form.admin.caption": "Администратор",

    "UserPage.gender.MALE.caption": "Мужчина",
    "UserPage.gender.FEMALE.caption": "Женщина",
    "UserPage.gender.OTHER.caption": "Дельфин",

    "UserPage.merchant.DISABLED.caption": "Запрещено",
    "UserPage.merchant.NONE.caption": "Нет",
    "UserPage.merchant.WAIT_FOR_SEND.caption": "Ожидает отправки",
    "UserPage.merchant.SENT.caption": "Отправлено",
    "UserPage.merchant.REJECTED.caption": "Отказано",
    "UserPage.merchant.ACCEPTED.caption": "Подтверждено",

    "UserPage.deleteButton.caption": "Удалить",
    "UserPage.deleteButton.title": "Удалить пользователя",
    "UserPage.resetButton.caption": "Сбросить",
    "UserPage.resetButton.title": "Сбросить изменения",
    "UserPage.saveButton.caption": "Сохранить",
    "UserPage.saveButton.title": "Сохранить изменения",
    "UserPage.backButton.caption": "Назад",
    "UserPage.backButton.title": "Назад к пользователям",
    "UserPage.setPasswordButton.caption": "Установить пароль",
    "UserPage.logoutAll.message": "Все сеансы пользователя были завершены",

    "UserPage.deleteConfirm.title": "Удалить пользователя?",
    "UserPage.deleteConfirm.message": "Вы действительно хотите удалить этого пользователя?",
    "UserPage.deleteConfirm.confirmButton.caption": "Удалить",
    "UserPage.deleteConfirm.cancelButton.caption": "Отмена",

    "UserPage.passwordDialog.caption": "Установка пароля",
    "UserPage.passwordDialog.password.caption": "Пароль",
    "UserPage.passwordDialog.okButton.caption": "ОК",
    "UserPage.passwordDialog.cancelButton.caption": "Отмена",

    "SelectUserDialog.title": "Выбор пользователей",
    "SelectUserDialog.UserTable.username": "Имя пользователя",
};
export default ru;
