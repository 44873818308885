const en = {
    "App.caption": "Cannawayz",

    "WelcomePage.service.AUTH-SERVICE.description": "Authentication service",
    "WelcomePage.service.DISCOVERY-SERVICE.description": "Discovery service",
    "WelcomePage.service.GATEWAY-SERVICE.description": "Application gateway",
    "WelcomePage.service.GRAPHQL-SERVICE.description": "GraphQL dispatcher",
    "WelcomePage.service.LOCATION-SERVICE.description": "Geolocation service",
    "WelcomePage.service.USER-SERVICE.description": "User service",
    "WelcomePage.service.VENDOR-SERVICE.description": "Shops and Brands service",

    "ShopWorkHours.1.caption": "Monday",
    "ShopWorkHours.2.caption": "Tuesday",
    "ShopWorkHours.3.caption": "Wednesday",
    "ShopWorkHours.4.caption": "Thursday",
    "ShopWorkHours.5.caption": "Friday",
    "ShopWorkHours.6.caption": "Saturday",
    "ShopWorkHours.7.caption": "Sunday",

    "VendorForm.socialGroup.WWW.caption": "Web site",
    "VendorForm.socialGroup.YOUTUBE.caption": "YouTube",
    "VendorForm.socialGroup.FACEBOOK.caption": "Facebook",
    "VendorForm.socialGroup.INSTAGRAM.caption": "Instagram",
    "VendorForm.socialGroup.TWITTER.caption": "X (formerly Twitter)",
    "VendorForm.socialGroup.LICENSE_1.caption": "License (1)",
    "VendorForm.socialGroup.LICENSE_2.caption": "License (2)",
    "VendorForm.socialGroup.LICENSE_3.caption": "License (3)",
    "VendorForm.socialGroup.LICENSE_4.caption": "License (4)",
    "VendorForm.socialGroup.LICENSE_5.caption": "License (5)",
    "VendorForm.amenitiesGroup.AMENITIES_AGE_18.caption": "Age 18+",
    "VendorForm.amenitiesGroup.AMENITIES_AGE_19.caption": "Age 19+",
    "VendorForm.amenitiesGroup.AMENITIES_AGE_21.caption": "Age 21+",
    "VendorForm.amenitiesGroup.AMENITIES_ACCESSIBLE.caption": "Accessible",
    "VendorForm.amenitiesGroup.AMENITIES_ATM.caption": "ATM",
    "VendorForm.amenitiesGroup.AMENITIES_BRAND_VERIFIED.caption": "Brand verified",
    "VendorForm.amenitiesGroup.AMENITIES_CURBSIDE_PICKUP.caption": "Curbside pickup",
    "VendorForm.amenitiesGroup.AMENITIES_PHOTOS.caption": "Photos",
    "VendorForm.amenitiesGroup.AMENITIES_SECURITY.caption": "Security",

    "UserListPage.role.cannawayz:accidents:view": "Accidents:View",
    "UserListPage.role.cannawayz:accidents:admin": "Accidents:Admin",
    "UserListPage.role.cannawayz:brands:view": "Brands:View",
    "UserListPage.role.cannawayz:brands:admin": "Brands:Admin",
    "UserListPage.role.cannawayz:shops:view": "Shops:View",
    "UserListPage.role.cannawayz:shops:admin": "Shops:Admin",
    "UserListPage.role.cannawayz:users:view": "Users:View",
    "UserListPage.role.cannawayz:users:admin": "Users:Admin",
    "UserListPage.role.cannawayz:super:admin": "Admin",
};
export default en;
