import { lazy } from "react";
import PageLayout from "./components/PageLayout";
import { EuiI18n } from "@elastic/eui";
import ErrorPage from "./pages/ErrorPage";
import NotFoundErrorPage from "./pages/NotFoundErrorPage";
import {
    ROLE_CW_ADS_ADMIN,
    ROLE_CW_ADS_VIEW,
    ROLE_CW_BRANDS_ADMIN,
    ROLE_CW_BRANDS_VIEW,
    ROLE_CW_SHOPS_ADMIN,
    ROLE_CW_SHOPS_VIEW,
    ROLE_CW_SUPER_ADMIN,
    ROLE_CW_USERS_ADMIN,
    ROLE_CW_USERS_VIEW,
} from "./roles";
import AdsListPage from "./pages/ads/AdsListPage";

const WelcomePage = lazy(() => import("./pages/welcome/WelcomePage"));

const UserListPage = lazy(() => import("./pages/users/UserListPage"));
const UserPage = lazy(() => import("./pages/users/UserPage"));

const BrandListPage = lazy(() => import("./pages/vendors/BrandListPage"));
const BrandPage = lazy(() => import("./pages/vendors/BrandPage"));

const ShopListPage = lazy(() => import("./pages/vendors/ShopListPage"));
const ShopPage = lazy(() => import("./pages/vendors/ShopPage"));

const routes = [
    {
        path: "/",
        element: <PageLayout />,
        errorElement: <ErrorPage header={false} />,
        children: [
            {
                index: true,
                element: <WelcomePage />,
            },
            {
                path: "ads",
                caption: <EuiI18n token={`App.header.ads.caption`} default="Ads" />,
                element: <AdsListPage />,
                roles: [ROLE_CW_SUPER_ADMIN, ROLE_CW_ADS_VIEW, ROLE_CW_ADS_ADMIN],
                showInHeader: true,
            },
            {
                path: "brands",
                caption: <EuiI18n token={`App.header.brands.caption`} default="Brands" />,
                element: <BrandListPage />,
                roles: [ROLE_CW_SUPER_ADMIN, ROLE_CW_BRANDS_VIEW, ROLE_CW_BRANDS_ADMIN],
                showInHeader: true,
            },
            {
                path: "brands/:brandId/*",
                element: <BrandPage />,
                roles: [ROLE_CW_SUPER_ADMIN, ROLE_CW_BRANDS_VIEW, ROLE_CW_BRANDS_ADMIN],
            },
            {
                path: "shops",
                caption: <EuiI18n token={`App.header.shops.caption`} default="Shops" />,
                element: <ShopListPage />,
                roles: [ROLE_CW_SUPER_ADMIN, ROLE_CW_SHOPS_VIEW, ROLE_CW_SHOPS_ADMIN],
                showInHeader: true,
            },
            {
                path: "shops/:shopId/*",
                element: <ShopPage />,
                roles: [ROLE_CW_SUPER_ADMIN, ROLE_CW_SHOPS_VIEW, ROLE_CW_SHOPS_ADMIN],
            },
            {
                path: "users",
                caption: <EuiI18n token={`App.header.users.caption`} default="Users" />,
                element: <UserListPage />,
                roles: [ROLE_CW_SUPER_ADMIN, ROLE_CW_USERS_VIEW, ROLE_CW_USERS_ADMIN],
                showInHeader: true,
            },
            {
                path: "users/:userId/*",
                element: <UserPage />,
                roles: [ROLE_CW_SUPER_ADMIN, ROLE_CW_USERS_VIEW, ROLE_CW_USERS_ADMIN],
            },
            {
                path: "*",
                element: <NotFoundErrorPage header={false} />,
            },
        ],
    },
];
export default routes;
